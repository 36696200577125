.home-section{
    min-height: 92vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

#snow-container {
    position: relative;
    overflow: hidden;
  }
  

  .snowflakes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }