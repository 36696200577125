.navbar{
    margin-top: 1.5rem;
    height: 8vh;
    min-height: 3rem;
    width: 80%;
    max-width: 100%;
    position: absolute;
    font-weight: 600;
    background-color: #385170;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    padding:0 2rem 0 2rem;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    transform: translateX(-50%); 
    z-index: 500;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.10) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.navbar h2{
    color: #9fd3c7;
    font-size: 1.8rem;
    padding-left: 2rem;
}

.navbar h2:hover{
    cursor: pointer
}

.navbar-links{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    font-size: 1.4rem;
    margin-left: 10%;
}

.navbar-links a{
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    color: #ececec;
    cursor: pointer;
}



.navbar-links a {
    padding: .5em .8em;
    color: white;
    position: relative;
    text-decoration: none;
    font-size: 20px;
  }
  
  .navbar-links a::before,
  .navbar-links a::after {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    transition: all .35s ease;
    opacity: 0;
  }
  
  .navbar-links a::before {
    content: '';
    right: 0;
    top: 0;
    border-top: 3px solid #9fd3c7;
    border-right: 3px solid #9fd3c7;
    transform: translate(-100%, 50%);
  }
  
  .navbar-links a:after {
    content: '';
    left: 0;
    bottom: 0;
    border-bottom: 3px solid #9fd3c7;
    border-left: 3px solid #9fd3c7;
    transform: translate(100%, -50%)
  }
  
  .navbar-links a:hover:before,
  .navbar-links a:hover:after{
    transform: translate(0,0);
    opacity: 1;
  }
  
  .navbar-links a:hover {
    color: white;
  }




  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: 0.4s;
}

.hamburger:hover + .navbar-links,
.navbar-links:hover {
    display: flex !important;
    opacity: 1;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

@media (max-width: 880px) {

  .navbar{
    justify-content: space-between;
  }

    .navbar-links {
        display: none;
        flex-direction: column;
        width: 50%;
        position: absolute;
        top: 4rem; 
        right: 0%;
        box-shadow: rgba(0, 0, 0, 0.10) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        background-color: #385170;
        border-radius: 40px;
        z-index: 1;
        transition: display 0.3s ease;
    }

    .navbar-links.open {
        display: flex;
        
    }

    .hamburger:hover + .navbar-links,
    .navbar-links:hover {
        display: flex !important;
        opacity: 1;
    }

    .navbar-links a {
        margin: 10px;
    }

    .hamburger {
        display: flex;
    }

    .bar.open1 {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .bar.open2 {
        opacity: 0;
    }

    .bar.open3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}


.hamburger:hover {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}
