.projects-section{
    min-height: 100vh;
    width: 100%;
    animation: fadeIn 1s ease-in-out forwards;
}


.projects-section h2{
    color:white;
    font-family: "Poppins", sans-serif;
    font-size: 2.5em;
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.projects{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
}


.project-cards {
    min-height: 60vh;
    width: 70%;
    margin-top: 5rem;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); 
    gap: 20px;
    justify-content: space-evenly;
    align-items: stretch;
    grid-auto-rows: 1fr;
}

@media (max-width: 1500px) {
    .project-cards {
        grid-template-columns: repeat(auto-fit, minmax(48%, 1fr)); 
    }
}