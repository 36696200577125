.AboutImage {
    position: relative;
    width: 90%;
    margin: 2rem auto;

}



@media (max-width: 1000px) {

    .AboutImage {
        position: relative;
        width: 50%;
        margin: 2rem auto;
    }
}