.project-card{
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 300ms;
    border: 1px solid #e0e0e0;
    flex-direction: column;
    display: flex;
    align-items: center;
}


.project-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border: 1px solid #9fd3c7;
}

.project-card:hover h3 {
    color: #9fd3c7;
}


.title-github{
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin-top: 0.5rem;
    align-items: center; 
}

.title-github h3{
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    max-width: 80%;
    text-align: start;
}


.project-description{
    margin-top: 1rem;
    margin-left: 1rem;
    text-align: start;
    text-justify: auto;
    min-height: 50%;
    width: 90%;
    align-self: flex-start;
    color: white;
}

.project-description p{
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
}


.project-tech{
    display: flex;
    flex-direction: row;
    height: 10%;
    width: 75%;
    align-items: center;
    justify-content: space-evenly;
}

.project-tech div{
    padding: 0.3rem 0.3rem;
    color: white;
    font-size: 0.8rem;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    
}



