.ContactMe-section{
    min-height: 100vh;
    width: 100%;
    animation: fadeIn 1s ease-in-out forwards;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


.ContactMe-section h2{
    color:white;
    font-family: "Poppins", sans-serif;
    font-size: 2.5em;
    padding-top: 3rem;
    padding-bottom: 1rem;
}



.contactme{
    display: grid;
    grid-template-columns: 70% 30%;
    height: 80%;
    width: 75%;
    align-self: center;

}


.first-column{
  
    height: 100%;
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 50% 50%; 
}


.image-and-name {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.image-container {
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
    border-radius: 2rem;
}

.image-and-name h3{
    color: white;
    font-size: 2.5rem;
}

.profile-image {
    width: 100%;
    height: auto;
}

.mycvs {
   justify-content: center;
    align-items: center;
}

.mycvs h3{
    color: white;
    font-size: 2rem;
    margin-top: 4rem;
}

.mycvs p{
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}


.buttons-downloads{
    height: 50%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    
}


.second-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.second-column h3{
    color: white;
    width: 100%;
    font-size: 1.8rem;
    margin-left: 2rem;
}


.second-column ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    
}

.second-column ul li{
    list-style: none;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.second-column ul li{
    position: relative;
    display: block;
    height: 100px;
    width:100px;
    background-color: #142d4c;
    line-height: 100px;
    text-align: center;
    color: white;
    border: 4px solid white;
    font-size: 3em;
    border-radius: 50%;
    transition: 0.5s;
}

.second-column ul li:before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--color);
    transform: scale(0.9);
    z-index: -1;
}

.second-column ul li:hover:before{
    transform: scale(1.1);
    box-shadow: 0px 0px 20px var(--color);
}

.second-column ul li:hover{
    color: var(--color);
    box-shadow: 0px 0px 8px var(--color);
    text-shadow: 0px 0px 8px var(--color);
    border: transparent;
}


.contact-footer {
    grid-column: span 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}



.email-number-etc{
    width: 80%;
    justify-content: space-evenly;
    height: 50%;
    display: flex;
    
}

.email-number-etc p{
    color: white;
    font-size: 1.2rem;
}

.email-number-etc div{
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


.copyright{
    width: 80%;
    height: 50%;
    display: flex;
    justify-content: space-evenly;
}

.copyright p{
    color: white;
    font-size: 1.2rem;
}

.copyright2 {
    display: none;
  }



@media (max-width: 1300px) {


    .contactme{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-self: center;
    }

    .first-column{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .mycvs {
        justify-content: center;
        align-items: center;
     }

    .buttons-downloads{
        height: 60%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        display: flex;
        margin-top: 2rem;
        
    }

    .buttons-downloads button{
        margin-bottom: 1.5rem;
        width: 40%;
    }

    .image-and-name h3{
        color: white;
        font-size: 1.8rem;
    }

    .mycvs h3{
        color: white;
        font-size: 2rem;
        margin-top: 2rem;
    }


    .contact-footer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .email-number-etc{
        width: 80%;
        flex-direction: column;
        height: 50%;
        display: flex;
        justify-content: center;
        
    }

    .email-number-etc p{
        color: white;
        font-size: 1.2rem;
    }
    
    .email-number-etc div{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .contact-info svg {
        margin-right: 1rem;
      }

    .copyright {
        display: none;
      }



      .copyright2{
        width: 80%;
        height: 50%;
        align-self: center;
        display: flex;
        justify-content: space-evenly;
        margin: 2rem 0;
    }
    
    .copyright2 p{
        color: white;
        font-size: 1em;
    }


    .second-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        width: 90%;
    }
    
    .second-column h3{
        color: white;
        width: 100%;
        font-size: 1.4rem;
    }
    

    .second-column ul{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
        justify-content: space-between;
    }
    
    .second-column ul li{
        list-style: none;
        cursor: pointer;
        margin-bottom: 0rem;
    }

    .second-column ul li{
        position: relative;
        display: block;
        height: 50px;
        width:50px;
        background-color: #142d4c;
        line-height: 50px;
        text-align: center;
        color: white;
        border: 4px solid white;
        font-size: 2em;
        border-radius: 50%;
        transition: 0.5s;
    }
    
    .second-column ul li:before{
        content: "";
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--color);
        transform: scale(0.9);
        z-index: -1;
    }

    .second-column ul li:first-child {
        margin-left: -2.5rem;
    }
    
    
}


@media (min-width:780px) and (max-width:1299px){
    .second-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        width: 90%;
    }
    
    .second-column h3{
        color: white;
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
    

    .second-column ul{
        display: flex;
        flex-direction: row;
        width: 60%;
        align-items: center;
        justify-content: space-between;
    }
    
    .second-column ul li{
        list-style: none;
        cursor: pointer;
        margin-bottom: 0rem;
    }

    .second-column ul li{
        position: relative;
        display: block;
        height: 80px;
        width:80px;
        background-color: #142d4c;
        line-height: 80px;
        text-align: center;
        color: white;
        border: 4px solid white;
        font-size: 2.5em;
        border-radius: 50%;
        transition: 0.5s;
    }
    
    .second-column ul li:before{
        content: "";
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--color);
        transform: scale(0.9);
        z-index: -1;
    }

    .second-column ul li:first-child {
        margin-left: -2.5rem;
    }
}


@media (max-width:779px){
    .second-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        width: 90%;
    }
    
    .second-column h3{
        color: white;
        width: 100%;
        font-size: 1.4rem;
        margin-left: 0;
    }
    

    .second-column ul{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
        justify-content: space-between;
    }
    
    .second-column ul li{
        list-style: none;
        cursor: pointer;
        margin-bottom: 0rem;
    }

    .second-column ul li{
        position: relative;
        display: block;
        height: 50px;
        width:50px;
        background-color: #142d4c;
        line-height: 50px;
        text-align: center;
        color: white;
        border: 4px solid white;
        font-size: 2em;
        border-radius: 50%;
        transition: 0.5s;
    }
    
    .second-column ul li:before{
        content: "";
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--color);
        transform: scale(0.9);
        z-index: -1;
    }

    .second-column ul li:first-child {
        margin-left: -2.5rem;
    }
}