@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.hometitle{
    position: relative;
    font-family: "Poppins", sans-serif;
}


.hometitle h2{
    color: #fff;
	font-size: 9em;
	position: absolute;
	transform: translate(-50%, -70%);
    width: 60rem;
}

.system-print {
    display: flex;
    align-items: center; 
    justify-content: center; 
    position: absolute;
    transform: translate(-50%, 700%);
    color: #fff;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    min-width: 200vh;
    white-space: nowrap; 
}

.system-print h3 {
    margin: 0 5px; 
    flex-shrink:0;
}


.hometitle h2:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px white;
}

.hometitle h2:nth-child(2) {
	color: #9fd3c7;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}


@media (max-width: 880px) {
    .hometitle h2 {
        width: 30rem;
        font-size: 6em;
    }

    .hometitle div{
        transform: translate(-50%, 600%);
        font-size: 1em;
    }

	.system-print h3{
		margin-bottom: 20px;
		font-size: 1em ;
	}
}


@media (max-width:500px) {
	.system-print h3 {
        margin-bottom: 30px;
		font-size:0.8em; 
    }
}

@media (max-width:400px){
	.system-print h3 {
		margin-bottom: 25px;
		font-size:0.6em; 
	}

}



.greeting {
    opacity: 0;
    animation: fadeInOut 6s ease-in-out infinite;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    25%, 75% {
        opacity: 1;
    }
}