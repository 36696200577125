.project-tabs{
    width: 40%;
    min-height: 6vh;
    align-self: center;
    justify-self: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
    border: 5px solid white;
}



.project-tabs div{
    color: white;
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    height: 80%;
    width: 50%;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}


.project-tabs div:hover{
    cursor: pointer;
    color: #9fd3c7;
    text-decoration: underline;
    text-decoration-skip-ink: none; 
    text-underline-offset: 10px; 
    text-decoration-thickness: 5px;

}

.project-tabs div:not(:first-child) {
    border-left: 3px solid white;
}

.project-tabs div:first-child{
    border-radius: 50px 0 0 50px;
}

.project-tabs div:last-child{
    border-radius: 0 50px 50px 0;
}

.tab.active {
    color: #9fd3c7;
}


@media (min-width: 781px) and(max-width: 1300px) {
    
    .project-tabs{
        width: 60%;
    }
}


@media (max-width: 780px) {
    
    .project-tabs{
        width: 80%;
    }
}