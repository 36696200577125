.popup2-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%; /* Garantir que ocupe a altura total */
    width: 100%;
  }
  
  

  .popup2-content iframe{
    margin: auto;
  }


  .popup2 {
    position: fixed;
    bottom: 10px;
    right: 20px;
    margin: 70px auto;
    background-color: #385170;
    border-radius: 40px;
    width: 25%;
    box-shadow: rgba(52, 50, 50, 0.1) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    animation: slideIn 0.5s forwards;
    z-index: 999;
    color: white;
    border: 2px solid transparent;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
  align-items: center; 
  }

  .popup2::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 38px; 
    padding: 4px; 
    background:linear-gradient(to right,#9fd3c7,#42615b); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  
}


.popup2 h2 {
  margin-top: 0;
  color: white;
  font-family: Tahoma, Arial, sans-serif;
  position: relative;
}


.popup2 .close {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 40px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
.popup2 .close:hover {
  color: #9fd3c7;
  cursor: pointer;
}
.popup2 .content {
  max-height: 40%;
  overflow: auto;
}

.popup2-content{
  font-size: 1rem;
  font-weight: 800;
  font-style: italic;
}

.popup2-author{
  font-size: 0.8rem;
  font-weight: 600;
}



@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}


@media (max-width: 779px) {
.popup{
  width: 70%;
  position: fixed;
  bottom: 10px;
  align-self:center;
}

.popup h2 {
  margin-top: 0;
  color: white;
  margin-left: 1rem;
  text-align: start;
  font-size: 1rem;
  font-family: Tahoma, Arial, sans-serif;
  position: relative;
}

.popup-content{
  font-size: 0.8rem;
  font-weight: 800;
  font-style: italic;
}

}

@media (min-width:780px) and (max-width: 1300px) {

.popup{
  width: 35%;
}
}
